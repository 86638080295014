.react-table-partners .rt-tr-group {
  background-color: #fff;
}

.react-table-partners .rt-tr-group:nth-child(2n) {
  background-color: #f2f2f2;
}

.react-table-partners .rt-th,
.react-table-partners .rt-td {
  padding: 16px 8px !important;
  font-size: 14px;
  cursor: pointer;
}

.react-table-partners .align-center {
  justify-content: center;
}
