.--page-container {
  margin: 50px 100px 50px 100px;
}
.campaign-page-title {
  color: #076f9f;
  font-weight: 700;
  margin: 0px;
  font-size: 21pt;
  line-height: 40px;
  margin-bottom: 30px;
}
.campaign-page-sub-title {
  color: #076f9f;
  font-family: montserrat, Helvetica, sans-serif;
  margin: 0;
  margin-top: 25px;
  font-weight: 700;
}

.btn-simulate {
  background-color: #3dc6ef;
}

.btn-simulate:focus {
  background-color: #3dc6ef !important;
}

.additional-information p {
  word-break: break-all;
}
.campaign-page-short-description {
  width: 100%;
  word-break: break-all;
  display: inline-block;
  margin-bottom: 50px;
}

.campaign-page-values {
  font-size: 20px;
  margin: 0;
  font-weight: 700;
  color: #686c8b;
}
.campaign-page-values-align {
  display: inline-table;
  text-align: center;
}
.campaign-page-right-section-margin {
  text-align: center;
}
.campaign-page-values-info {
  font-size: 17px;
  margin: 0;
  font-weight: 700;
  color: #686c8b;
}

.campaign-progress {
  margin-top: 15px;
  margin-bottom: 35px;
}
.campaign-page-font {
  color: #686c8b;
  font-family: 'Rubik', sans-serif;
}
.campaign-page-font p {
  font-size: 13pt;
}
#campaign-page-info-desc {
  margin-top: 50px;
}
.nav-campaign-page-info-menu li {
  border-top: 2px solid transparent;
  float: left;
  line-height: 1;
  padding: 30px 15px 30px 15px;
  position: relative;
}
.campaign-page-active-link {
  color: #076f9f !important;
}
.campaign-page-info-current {
  border-bottom: 3px solid #076f9f;
}

.row-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row-desc-item {
  width: 100%;
  flex: 1;
}
.row-desc-item:nth-child(2) {
  margin-left: 50px;
}
.nav-campaign-page-info-menu-link {
  cursor: pointer;
  color: #686c8b;
  text-decoration: none;
  font-weight: lighter;
  font-size: 20px;
}
.nav-campaign-page-info-menu {
  border-top: 1px solid#e7e7e7;
  border-bottom: 1px solid#e7e7e7;
  clear: both;
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
}

#campaign-info-desc span,
#campaign-info-desc strong,
#campaign-info-desc b {
  text-transform: uppercase;
  font-size: 15pt;
  font-weight: 700;
  color: #076f9f;
}

#campaign-info-desc .ql-align-center {
  text-transform: uppercase;
  font-size: 15pt;
  font-weight: 700;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #076f9f;
}
#campaign-info-desc p.ql-align-center strong {
  color: #fff !important;
}

.campaign-page-photo-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  cursor: pointer;
}

.campaign-page-modal-img img {
  height: 100%;
  width: 100%;
}
.campaign-page-modal-img {
  cursor: pointer;
  animation-name: fade-in;
  animation-duration: 1s;
}
.campaign-page-photo-modal-content {
  background-color: #fff;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  border-radius: 5px;
  box-shadow: 5px 5px 55px rgb(0, 0, 0);
  position: relative;
}

@keyframes fade-in {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.flex {
  clear: both;
  display: flex;
}
.campaign-page-photo-modal-close {
  cursor: pointer;
  float: right;
  margin: 5px 5px 0 0;
}
.campaign-page-photo-modal-nav-wrapper-previous:hover .previous {
  opacity: 1;
}
.campaign-page-photo-modal-nav-wrapper-previous {
  position: absolute;
  height: 95%;
  width: 50%;
  cursor: pointer;
}
.campaign-page-photo-modal-nav-wrapper-next:hover .next {
  opacity: 1;
}
.campaign-page-photo-modal-nav-wrapper-next {
  position: absolute;
  height: 95%;
  width: 50%;
  right: 0;
  cursor: pointer;
}
.campaign-page-photo-modal-nav {
  margin: auto;
  cursor: pointer;
}
.round {
  position: absolute;
  border-radius: 50%;
  background-color: #222;
  color: #e7e7e7;
  border: 2px solid #e7e7e7;
  height: 27px;
  width: 27px;
  font-size: 12pt;

  box-shadow: 1px 1px 5px rgb(0, 0, 0);
}
.next {
  top: 47%;
  right: 15px;
  opacity: 0;
}
.previous {
  top: 47%;
  left: 15px;
  opacity: 0;
}
.close {
  top: -20px;
  right: -20px;
}

.campaign-page-img:hover {
  opacity: 0.5;
}
.campaign-page-img {
  border: 1px solid #fff;
  cursor: pointer;
  padding: 0 !important;
  display: inline-block;
  position: relative;
}
.campaign-page-profile-img {
  width: 100%;
  animation-name: fade-in;
  animation-duration: 1s;
  display: inline-block;
  margin-bottom: 50px;
}
.campaign-page-profile-img img {
  height: 100%;
  width: 100%;
}

.campaign-page-update-round-marker:hover {
  background: #076f9f;
}
.campaign-page-update-round-marker {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #e7e7e7;
  display: inline-block;
  margin-left: -35px;
}
.campaign-page-update-list-item:hover .campaign-page-update-round-marker {
  background-color: #076f9f;
}
.campaign-page-update-title {
  display: inline-block;
  width: 100%;
}
.campaign-page-update-list {
  padding-top: 0px;
  border-left: 3px solid #e7e7e7;
  padding-left: 20px !important;
  padding-bottom: 20px;
  margin-bottom: 50px;
}

#campaign-updates,
#campaign-info-desc {
  position: relative;
  display: block;
}

#campaign-info-desc img {
  max-width: 100%;
}

#campaign-gallery {
  padding-bottom: 30px;
}
.img-list::-webkit-scrollbar {
  height: 4px;
}

.img-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.img-list::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #076f9f;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.img-list {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: baseline;
  align-content: center;

  width: 100%;
  position: relative;
  overflow-x: auto;
}

.campaign-page-gallery-img-list-item img {
  height: 100%;
  width: auto;
}
.campaign-page-gallery-img-list-item {
  height: 200px;
}

#toast-container * {
  font-size: 15pt;
  background-color: #076f9f;
}

@media only screen and (max-width: 700px) {
  .campaign-page-container {
    margin: 50px 15px 50px 15px;
  }

  .campaign-short-desc-padding {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .campaign-page-values-align {
    text-align: left;
  }
}

@media only screen and (max-width: 992px) {
  .campaign-page-right-section-margin {
    margin-left: 0px;
    padding-left: 0px;
  }
  .row-desc {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .row-desc-item {
    float: left;
    box-sizing: border-box;
    padding: 0 0.75rem;
    min-height: 1px;
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row-desc-item:nth-child(2) {
    margin: 0;
  }
}


.iframe__title {
  color: #076f9f;
  font-weight: 700;
  font-size: 1.3em !important;
  margin-bottom: 15px;
}

.campaign-title {
  text-align: center;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.campaign-image-container {
  width: 100%;
}

.progress {
  position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: #acece6;
    border-radius: 2px;
    margin: .5rem 0 1rem;
    overflow: hidden;
}

.campaign-progress {
  margin-top: 15px;
  margin-bottom: 35px;
}
