.my-investments .accordion-investments {
  display: flex;
  flex-direction: column;
}

.my-investments .title-investments {
  font-size: 1.2rem;
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 2px solid #3dc6ef;
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.my-investments .title-investments p {
  margin: 0 1em;
}

@media (max-width: 769px) {
  .my-investments .title-investments {
    font-size: 1rem;
  }
  .my-investments .title-investments p {
    margin: 0.3em 1em;
  }
}

@media (max-width: 415px) {
  .my-investments .title-investments {
    flex-direction: column;
  }
}

.my-investments .title-investments:not(:first-of-type) {
  margin-top: 60px;
}

.my-investments p {
  margin: 0px;
  display: block;
}

.accordion {
  margin-bottom: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
}

.my-investments .investments-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  min-height: 150px;
  position: relative;
}

.my-investments .investments-container .investments-row {
  width: 100%;
  display: flex;
  flex: 1;
}

@media (max-width: 415px) {
  .my-investments .investments-container .investments-row {
    flex-direction: column;
  }
}

.my-investments .investments-container .investments-row .investments-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 14px;
}

.my-investments .investments-container .investments-row .investments-content.--img {
  background-color: white;
  min-height: 150px;
}

.my-investments .investments-container .investments-row .investments-content.--img img {
  align-self: center;
  width: 213px;
  height: 128px;
}

.my-investments .investments-container .investments-row .investments-content .investments-title {
  font-weight: 600;
  margin-bottom: 10px;
  word-break: normal;
  text-align: center;
}

.my-investments .investments-container .toggle-modal-btn {
  bottom: 15px;
  right: 15px;
  position: absolute;
  background-color: #076f9f;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  color: white;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .my-investments .investments-container {
    flex-direction: column;
    flex: 1;
  }
  .my-investments .investments-container .investments-row {
    flex: 1;
    min-height: 100px;
  }
  .my-investments .investments-container .investments-row .investments-content.--img {
    min-height: auto;
  }
}

.my-investments .investment-actions {
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
}

.my-investments .investment-actions div button {
  font-size: 12px;
  padding: 12px;
}

.my-investments .investment-actions div:first-child {
  margin-right: 20px;
}

@media screen and (max-width: 575px) {
  .my-investments .investment-actions {
    flex-direction: column;
    align-items: center;
  }
  .my-investments .investment-actions div:first-child {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

.my-investments .accordion-investments tbody .accordion-table-row {
  cursor: pointer;
  padding: 1em 0;
  background-color: white;
  transition: all 0.3s;
}

@media (max-width: 769px) {
  .my-investments .accordion-investments tbody .accordion-table-row {
    padding: 1em 0.2em;
    font-size: 14px;
  }
}

.my-investments .accordion-investments tbody .accordion-table-row:hover {
  background-color: rgba(0, 0, 0, 0.075);
  transition: all 0.3s;
}

.my-investments .accordion-investments .accordion-table-row {
  display: flex;
}

.my-investments .accordion-investments .accordion-table-row .accordion-table-cell {
  font-weight: normal;
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.my-investments .accordion-investments .accordion-table-row .accordion-table-cell p {
  padding: 0 0.5em;
  margin-right: 1.6em;
}

@media (max-width: 769px) {
  .my-investments .accordion-investments .accordion-table-row .accordion-table-cell p {
    margin-right: 0px;
  }
}

.my-investments .accordion-investments .accordion-table-row .accordion-table-cell.--header {
  font-weight: 600;
  padding: 1em 0;
}

.my-investments .pledges-container {
  position: relative;
  display: flex;
  background-color: #f0f0f0;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.my-investments .pledges-container .pledges-row {
  display: flex;
  height: 100%;
  width: 50%;
  min-height: 150px;
  flex: 2;
}

@media (max-width: 1025px) {
  .my-investments .pledges-container .pledges-row {
    align-items: center;
  }
}

.my-investments .pledges-container .pledges-content {
  padding: 14px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 150px;
}

@media (max-width: 769px) {
  .my-investments .pledges-container .pledges-content {
    min-height: initial;
  }
}

.my-investments .pledges-container .pledges-content:first-of-type {
  background-color: white;
}

.my-investments .pledges-container .pledges-content:first-of-type img {
  align-self: center;
  height: 128px;
  width: 213px;
}

.my-investments .pledges-container .pledges-content .pledges-title {
  font-weight: 600;
  margin-bottom: 10px;
  width: 200px;
  text-align: center;
}

@media (max-width: 1025px) {
  .my-investments .pledges-container .pledges-content .pledges-title {
    width: initial;
  }
}

.my-investments .pledges-container .pledges-content-bigger {
  padding: 14px;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 150px;
}

@media (max-width: 1025px) {
  .my-investments .pledges-container .pledges-content-bigger {
    width: 30%;
  }
}

.my-investments .pledges-container .pledges-content-bigger .pledges-steps-container {
  display: flex;
  justify-content: center;
}

.my-investments .pledges-container .pledges-content-bigger .pledges-steps-container .pledges-steps {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.my-investments .pledges-container .pledges-content-bigger .pledges-steps-container .pledges-steps i {
  font-size: 16px;
  margin-right: 5px;
}

.my-investments .pledges-container .pledges-content-bigger .pledges-steps-container .icon-close {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 575px) {
  .my-investments .pledges-container {
    flex-direction: column;
  }
  .my-investments .pledges-container .pledges-row,
  .my-investments .pledges-container .pledges-content-bigger {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

.Modal {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
}

@media (max-width: 575px) {
  .Modal {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    overflow-y: scroll;
  }
}

.Modal p {
  margin: 0;
}

.Modal .modal-header {
  display: flex;
  justify-content: space-between;
}

.Modal .modal-header h4 {
  margin-top: 0px;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .Modal .modal-header h4 {
    font-size: 1em;
    margin-bottom: 15px;
  }
}

.Modal .modal-header i {
  margin-top: 5px;
  cursor: pointer;
}

.Modal .table-modal {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
}

.Modal .table-modal .modal-row {
  display: flex;
  padding: 0.75rem 1.25rem;
}

@media (max-width: 575px) {
  .Modal .table-modal .modal-row {
    padding: 0.4rem 1rem;
  }
}

.Modal .table-modal .modal-row p {
  flex: 1;
  text-align: center;
}

@media (max-width: 575px) {
  .Modal .table-modal .modal-row p {
    font-size: 0.83em;
  }
}

.Modal .table-modal .modal-row:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-container-anticipation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}

.modal-footer {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}

.modal-footer p {
  padding: 1em;
}

.button {
  padding: 0.7em 1em;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin: 0 0.8em;
}
.button:focus {
  box-shadow: none;
  background-color: white !important;
}

.button--red {
  color: #fff;
  background: #a80303;
  transition: all 0.3s;
}
.button--red:hover {
  background-color: #880202 !important;
}
.button--red:focus {
  background-color: #880202 !important;
}

.button--bordered {
  border: 1px solid #555;
  color: #555;
  transition: all 0.3s;
}
.button--bordered:hover {
  background: #555 !important;
  color: white;
  border: 1px solid white;
}

@media (max-width: 769px) {
  .button {
    width: 80%;
    margin: 1em 0;
  }
}
.green-text {
  color: green;
}

.investment-summary {
  display: flex;
  width: 100%;
  padding: 1.5em 0;
  font-weight: bold;
  justify-content: center;
}

.investment-summary p {
  margin: 0 0.2em;
}

@media (max-width: 769px) {
  .investment-summary {
    flex-direction: column;
    align-items: center;
  }

  .investment-summary p {
    margin: 0.2em 0;
  }
  .investment-summary *:not(:first-child):not(:last-child) {
    display: none;
  }
}
