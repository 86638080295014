.ModalOverlay {
  position: fixed;
  display: flex;
  margin-top: 78;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 12;
  width: 100%;
  height: calc(100% - 78px);
  justify-content: flex-start;
  align-items: flex-start;
}

.ModalSidebar {
  justify-content: flex-start;
  align-items: flex-start;
  border: none;
  background: #076f9f;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 0px;
  outline: none;
  width: 260px;
  height: 100%;
}

@media (min-width: 768px) {
  .ModalOverlay {
    display: none;
  }

  .ModalSidebar {
    display: none;
  }
}
