.react-autosuggest__container {
  width: 100%;
}

.react-autosuggest__suggestions-container {
  position: relative;
  height: auto;
}

.react-autosuggest__suggestion[aria-selected='true'] div {
  background: #eee;
}

.react-autosuggest__suggestion:focus {
  background: #eee;
}

.react-autosuggest__suggestions-container ul {
  list-style-type: none;
}

.react-autosuggest__suggestions-list {
  position: absolute;
  overflow: scroll;
  background: #eeeeee;
  z-index: 5;
  cursor: pointer;
  height: 400px;
}
