.ModalDetails {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
}

@media (max-width: 769px) {
  .ModalDetails {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
}

.OverlayModalDetails {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
