.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 140px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
  background-color: white;
}

.loading-small {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  background-color: white;
}

.loading-icon {
  font-size: 40px;
  text-align: center;
  color: #adb5bd;
  margin-bottom: 20px;
}

#loading-logo {
  width: 40px;
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(359deg);
            transform: rotateY(359deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(359deg);
  }
}

.quill {
  width: 100%;
  padding: 0 1em;
}

.ql-editor {
  background: rgb(219, 219, 219);
}


.\--page-container {
  margin: 50px 100px 50px 100px;
}
.campaign-page-title {
  color: #076f9f;
  font-weight: 700;
  margin: 0px;
  font-size: 21pt;
  line-height: 40px;
  margin-bottom: 30px;
}
.campaign-page-sub-title {
  color: #076f9f;
  font-family: montserrat, Helvetica, sans-serif;
  margin: 0;
  margin-top: 25px;
  font-weight: 700;
}

.btn-simulate {
  background-color: #3dc6ef;
}

.btn-simulate:focus {
  background-color: #3dc6ef !important;
}

.additional-information p {
  word-break: break-all;
}
.campaign-page-short-description {
  width: 100%;
  word-break: break-all;
  display: inline-block;
  margin-bottom: 50px;
}

.campaign-page-values {
  font-size: 20px;
  margin: 0;
  font-weight: 700;
  color: #686c8b;
}
.campaign-page-values-align {
  display: inline-table;
  text-align: center;
}
.campaign-page-right-section-margin {
  text-align: center;
}
.campaign-page-values-info {
  font-size: 17px;
  margin: 0;
  font-weight: 700;
  color: #686c8b;
}

.campaign-progress {
  margin-top: 15px;
  margin-bottom: 35px;
}
.campaign-page-font {
  color: #686c8b;
  font-family: 'Rubik', sans-serif;
}
.campaign-page-font p {
  font-size: 13pt;
}
#campaign-page-info-desc {
  margin-top: 50px;
}
.nav-campaign-page-info-menu li {
  border-top: 2px solid transparent;
  float: left;
  line-height: 1;
  padding: 30px 15px 30px 15px;
  position: relative;
}
.campaign-page-active-link {
  color: #076f9f !important;
}
.campaign-page-info-current {
  border-bottom: 3px solid #076f9f;
}

.row-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row-desc-item {
  width: 100%;
  flex: 1 1;
}
.row-desc-item:nth-child(2) {
  margin-left: 50px;
}
.nav-campaign-page-info-menu-link {
  cursor: pointer;
  color: #686c8b;
  text-decoration: none;
  font-weight: lighter;
  font-size: 20px;
}
.nav-campaign-page-info-menu {
  border-top: 1px solid#e7e7e7;
  border-bottom: 1px solid#e7e7e7;
  clear: both;
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
}

#campaign-info-desc span,
#campaign-info-desc strong,
#campaign-info-desc b {
  text-transform: uppercase;
  font-size: 15pt;
  font-weight: 700;
  color: #076f9f;
}

#campaign-info-desc .ql-align-center {
  text-transform: uppercase;
  font-size: 15pt;
  font-weight: 700;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #076f9f;
}
#campaign-info-desc p.ql-align-center strong {
  color: #fff !important;
}

.campaign-page-photo-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  cursor: pointer;
}

.campaign-page-modal-img img {
  height: 100%;
  width: 100%;
}
.campaign-page-modal-img {
  cursor: pointer;
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.campaign-page-photo-modal-content {
  background-color: #fff;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  border-radius: 5px;
  box-shadow: 5px 5px 55px rgb(0, 0, 0);
  position: relative;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.flex {
  clear: both;
  display: flex;
}
.campaign-page-photo-modal-close {
  cursor: pointer;
  float: right;
  margin: 5px 5px 0 0;
}
.campaign-page-photo-modal-nav-wrapper-previous:hover .previous {
  opacity: 1;
}
.campaign-page-photo-modal-nav-wrapper-previous {
  position: absolute;
  height: 95%;
  width: 50%;
  cursor: pointer;
}
.campaign-page-photo-modal-nav-wrapper-next:hover .next {
  opacity: 1;
}
.campaign-page-photo-modal-nav-wrapper-next {
  position: absolute;
  height: 95%;
  width: 50%;
  right: 0;
  cursor: pointer;
}
.campaign-page-photo-modal-nav {
  margin: auto;
  cursor: pointer;
}
.round {
  position: absolute;
  border-radius: 50%;
  background-color: #222;
  color: #e7e7e7;
  border: 2px solid #e7e7e7;
  height: 27px;
  width: 27px;
  font-size: 12pt;

  box-shadow: 1px 1px 5px rgb(0, 0, 0);
}
.next {
  top: 47%;
  right: 15px;
  opacity: 0;
}
.previous {
  top: 47%;
  left: 15px;
  opacity: 0;
}
.close {
  top: -20px;
  right: -20px;
}

.campaign-page-img:hover {
  opacity: 0.5;
}
.campaign-page-img {
  border: 1px solid #fff;
  cursor: pointer;
  padding: 0 !important;
  display: inline-block;
  position: relative;
}
.campaign-page-profile-img {
  width: 100%;
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  display: inline-block;
  margin-bottom: 50px;
}
.campaign-page-profile-img img {
  height: 100%;
  width: 100%;
}

.campaign-page-update-round-marker:hover {
  background: #076f9f;
}
.campaign-page-update-round-marker {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #e7e7e7;
  display: inline-block;
  margin-left: -35px;
}
.campaign-page-update-list-item:hover .campaign-page-update-round-marker {
  background-color: #076f9f;
}
.campaign-page-update-title {
  display: inline-block;
  width: 100%;
}
.campaign-page-update-list {
  padding-top: 0px;
  border-left: 3px solid #e7e7e7;
  padding-left: 20px !important;
  padding-bottom: 20px;
  margin-bottom: 50px;
}

#campaign-updates,
#campaign-info-desc {
  position: relative;
  display: block;
}

#campaign-info-desc img {
  max-width: 100%;
}

#campaign-gallery {
  padding-bottom: 30px;
}
.img-list::-webkit-scrollbar {
  height: 4px;
}

.img-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.img-list::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #076f9f;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.img-list {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: baseline;
  align-content: center;

  width: 100%;
  position: relative;
  overflow-x: auto;
}

.campaign-page-gallery-img-list-item img {
  height: 100%;
  width: auto;
}
.campaign-page-gallery-img-list-item {
  height: 200px;
}

#toast-container * {
  font-size: 15pt;
  background-color: #076f9f;
}

@media only screen and (max-width: 700px) {
  .campaign-page-container {
    margin: 50px 15px 50px 15px;
  }

  .campaign-short-desc-padding {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .campaign-page-values-align {
    text-align: left;
  }
}

@media only screen and (max-width: 992px) {
  .campaign-page-right-section-margin {
    margin-left: 0px;
    padding-left: 0px;
  }
  .row-desc {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .row-desc-item {
    float: left;
    box-sizing: border-box;
    padding: 0 0.75rem;
    min-height: 1px;
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row-desc-item:nth-child(2) {
    margin: 0;
  }
}


.iframe__title {
  color: #076f9f;
  font-weight: 700;
  font-size: 1.3em !important;
  margin-bottom: 15px;
}

.campaign-title {
  text-align: center;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.campaign-image-container {
  width: 100%;
}

.progress {
  position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: #acece6;
    border-radius: 2px;
    margin: .5rem 0 1rem;
    overflow: hidden;
}

.campaign-progress {
  margin-top: 15px;
  margin-bottom: 35px;
}

.react-autosuggest__container {
  width: 100%;
}

.react-autosuggest__suggestions-container {
  position: relative;
  height: auto;
}

.react-autosuggest__suggestion[aria-selected='true'] div {
  background: #eee;
}

.react-autosuggest__suggestion:focus {
  background: #eee;
}

.react-autosuggest__suggestions-container ul {
  list-style-type: none;
}

.react-autosuggest__suggestions-list {
  position: absolute;
  overflow: scroll;
  background: #eeeeee;
  z-index: 5;
  cursor: pointer;
  height: 400px;
}

.upload-icon {
  width: 44px;
  height: 44px;
  margin: 8px 0;
  color: rgb(7, 111, 159);
  fill: rgb(7, 111, 159);
}
.ModalOverlay {
  position: fixed;
  display: flex;
  margin-top: 78;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 12;
  width: 100%;
  height: calc(100% - 78px);
  justify-content: flex-start;
  align-items: flex-start;
}

.ModalSidebar {
  justify-content: flex-start;
  align-items: flex-start;
  border: none;
  background: #076f9f;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 0px;
  outline: none;
  width: 260px;
  height: 100%;
}

@media (min-width: 768px) {
  .ModalOverlay {
    display: none;
  }

  .ModalSidebar {
    display: none;
  }
}

.ModalDetails {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
}

@media (max-width: 769px) {
  .ModalDetails {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
}

.OverlayModalDetails {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.success {
  margin-top: 110px;
  background: #076F9F;
  color: white;
}

.progress-bar {
  background: #fff;
}

.rt-tbody {
  overflow: visible !important;
}
.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}

.ReactTable {
  border: none;
  background: rgb(255, 251, 251);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 white;
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 white;
}

.ReactTableDefault .rt-th {
  padding: 16px !important;
  font-size: 14px;
  cursor: pointer;
}

.rt-tr:hover {
  background: rgb(228, 228, 228);
}

.rt-th {
  background-color: #076f9f;
  color: white;
  font-weight: 300;
  text-align: left;
}

.ReactTable .pagination-bottom {
  margin-top: 20px;
}
.ReactTable .-pagination {
  box-shadow: none;
  border: none;
}

.ReactTable .-pagination .-btn {
  text-transform: uppercase;
  font-size: 13px !important;
  letter-spacing: 1px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  line-height: 40px;
  border-radius: 2px;
  padding: 0px;
  max-width: 180px;
  display: inline-block;
  opacity: 1;
}

.ReactTable .-pagination .-previous button {
  float: right;
}
.ReactTable .-pagination .-next {
  text-align: left;
}
.ReactTable .-pagination button[disabled] {
  opacity: 0.1;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: none;
  background: none;
  width: 100%;
}

.my-investments .accordion-investments {
  display: flex;
  flex-direction: column;
}

.my-investments .title-investments {
  font-size: 1.2rem;
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 2px solid #3dc6ef;
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.my-investments .title-investments p {
  margin: 0 1em;
}

@media (max-width: 769px) {
  .my-investments .title-investments {
    font-size: 1rem;
  }
  .my-investments .title-investments p {
    margin: 0.3em 1em;
  }
}

@media (max-width: 415px) {
  .my-investments .title-investments {
    flex-direction: column;
  }
}

.my-investments .title-investments:not(:first-of-type) {
  margin-top: 60px;
}

.my-investments p {
  margin: 0px;
  display: block;
}

.accordion {
  margin-bottom: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
}

.my-investments .investments-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  min-height: 150px;
  position: relative;
}

.my-investments .investments-container .investments-row {
  width: 100%;
  display: flex;
  flex: 1 1;
}

@media (max-width: 415px) {
  .my-investments .investments-container .investments-row {
    flex-direction: column;
  }
}

.my-investments .investments-container .investments-row .investments-content {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 14px;
}

.my-investments .investments-container .investments-row .investments-content.\--img {
  background-color: white;
  min-height: 150px;
}

.my-investments .investments-container .investments-row .investments-content.\--img img {
  align-self: center;
  width: 213px;
  height: 128px;
}

.my-investments .investments-container .investments-row .investments-content .investments-title {
  font-weight: 600;
  margin-bottom: 10px;
  word-break: normal;
  text-align: center;
}

.my-investments .investments-container .toggle-modal-btn {
  bottom: 15px;
  right: 15px;
  position: absolute;
  background-color: #076f9f;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  color: white;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .my-investments .investments-container {
    flex-direction: column;
    flex: 1 1;
  }
  .my-investments .investments-container .investments-row {
    flex: 1 1;
    min-height: 100px;
  }
  .my-investments .investments-container .investments-row .investments-content.\--img {
    min-height: auto;
  }
}

.my-investments .investment-actions {
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
}

.my-investments .investment-actions div button {
  font-size: 12px;
  padding: 12px;
}

.my-investments .investment-actions div:first-child {
  margin-right: 20px;
}

@media screen and (max-width: 575px) {
  .my-investments .investment-actions {
    flex-direction: column;
    align-items: center;
  }
  .my-investments .investment-actions div:first-child {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

.my-investments .accordion-investments tbody .accordion-table-row {
  cursor: pointer;
  padding: 1em 0;
  background-color: white;
  transition: all 0.3s;
}

@media (max-width: 769px) {
  .my-investments .accordion-investments tbody .accordion-table-row {
    padding: 1em 0.2em;
    font-size: 14px;
  }
}

.my-investments .accordion-investments tbody .accordion-table-row:hover {
  background-color: rgba(0, 0, 0, 0.075);
  transition: all 0.3s;
}

.my-investments .accordion-investments .accordion-table-row {
  display: flex;
}

.my-investments .accordion-investments .accordion-table-row .accordion-table-cell {
  font-weight: normal;
  flex: 1 1;
  text-align: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.my-investments .accordion-investments .accordion-table-row .accordion-table-cell p {
  padding: 0 0.5em;
  margin-right: 1.6em;
}

@media (max-width: 769px) {
  .my-investments .accordion-investments .accordion-table-row .accordion-table-cell p {
    margin-right: 0px;
  }
}

.my-investments .accordion-investments .accordion-table-row .accordion-table-cell.\--header {
  font-weight: 600;
  padding: 1em 0;
}

.my-investments .pledges-container {
  position: relative;
  display: flex;
  background-color: #f0f0f0;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.my-investments .pledges-container .pledges-row {
  display: flex;
  height: 100%;
  width: 50%;
  min-height: 150px;
  flex: 2 1;
}

@media (max-width: 1025px) {
  .my-investments .pledges-container .pledges-row {
    align-items: center;
  }
}

.my-investments .pledges-container .pledges-content {
  padding: 14px;
  flex: 1 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 150px;
}

@media (max-width: 769px) {
  .my-investments .pledges-container .pledges-content {
    min-height: initial;
  }
}

.my-investments .pledges-container .pledges-content:first-of-type {
  background-color: white;
}

.my-investments .pledges-container .pledges-content:first-of-type img {
  align-self: center;
  height: 128px;
  width: 213px;
}

.my-investments .pledges-container .pledges-content .pledges-title {
  font-weight: 600;
  margin-bottom: 10px;
  width: 200px;
  text-align: center;
}

@media (max-width: 1025px) {
  .my-investments .pledges-container .pledges-content .pledges-title {
    width: initial;
  }
}

.my-investments .pledges-container .pledges-content-bigger {
  padding: 14px;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 150px;
}

@media (max-width: 1025px) {
  .my-investments .pledges-container .pledges-content-bigger {
    width: 30%;
  }
}

.my-investments .pledges-container .pledges-content-bigger .pledges-steps-container {
  display: flex;
  justify-content: center;
}

.my-investments .pledges-container .pledges-content-bigger .pledges-steps-container .pledges-steps {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.my-investments .pledges-container .pledges-content-bigger .pledges-steps-container .pledges-steps i {
  font-size: 16px;
  margin-right: 5px;
}

.my-investments .pledges-container .pledges-content-bigger .pledges-steps-container .icon-close {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 575px) {
  .my-investments .pledges-container {
    flex-direction: column;
  }
  .my-investments .pledges-container .pledges-row,
  .my-investments .pledges-container .pledges-content-bigger {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

.Modal {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
}

@media (max-width: 575px) {
  .Modal {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    overflow-y: scroll;
  }
}

.Modal p {
  margin: 0;
}

.Modal .modal-header {
  display: flex;
  justify-content: space-between;
}

.Modal .modal-header h4 {
  margin-top: 0px;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .Modal .modal-header h4 {
    font-size: 1em;
    margin-bottom: 15px;
  }
}

.Modal .modal-header i {
  margin-top: 5px;
  cursor: pointer;
}

.Modal .table-modal {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
}

.Modal .table-modal .modal-row {
  display: flex;
  padding: 0.75rem 1.25rem;
}

@media (max-width: 575px) {
  .Modal .table-modal .modal-row {
    padding: 0.4rem 1rem;
  }
}

.Modal .table-modal .modal-row p {
  flex: 1 1;
  text-align: center;
}

@media (max-width: 575px) {
  .Modal .table-modal .modal-row p {
    font-size: 0.83em;
  }
}

.Modal .table-modal .modal-row:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-container-anticipation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}

.modal-footer {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}

.modal-footer p {
  padding: 1em;
}

.button {
  padding: 0.7em 1em;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin: 0 0.8em;
}
.button:focus {
  box-shadow: none;
  background-color: white !important;
}

.button--red {
  color: #fff;
  background: #a80303;
  transition: all 0.3s;
}
.button--red:hover {
  background-color: #880202 !important;
}
.button--red:focus {
  background-color: #880202 !important;
}

.button--bordered {
  border: 1px solid #555;
  color: #555;
  transition: all 0.3s;
}
.button--bordered:hover {
  background: #555 !important;
  color: white;
  border: 1px solid white;
}

@media (max-width: 769px) {
  .button {
    width: 80%;
    margin: 1em 0;
  }
}
.green-text {
  color: green;
}

.investment-summary {
  display: flex;
  width: 100%;
  padding: 1.5em 0;
  font-weight: bold;
  justify-content: center;
}

.investment-summary p {
  margin: 0 0.2em;
}

@media (max-width: 769px) {
  .investment-summary {
    flex-direction: column;
    align-items: center;
  }

  .investment-summary p {
    margin: 0.2em 0;
  }
  .investment-summary *:not(:first-child):not(:last-child) {
    display: none;
  }
}

.react-table-partners .rt-tr-group {
  background-color: #fff;
}

.react-table-partners .rt-tr-group:nth-child(2n) {
  background-color: #f2f2f2;
}

.react-table-partners .rt-th,
.react-table-partners .rt-td {
  padding: 16px 8px !important;
  font-size: 14px;
  cursor: pointer;
}

.react-table-partners .align-center {
  justify-content: center;
}

.react-table-leads .rt-tr-group {
  background-color: #fff;
}

.react-table-leads .rt-tr-group:nth-child(2n) {
  background-color: #f2f2f2;
}

.react-table-leads .rt-th {
  cursor: pointer;
  padding: 16px 8px !important;
  font-size: 14px;
}

.react-table-leads .rt-td {
  padding: 0px !important;
  font-size: 14px;
  cursor: pointer;
}

.react-table-leads .rt-td {
  cursor: default;
  display: flex;
  align-items: center;
}

.react-table-leads .align-right {
  justify-content: flex-end;
}

.react-table-leads .align-center {
  justify-content: center;
}

.react-table-leads .rt-th {
  text-align: center;
}

.react-table-leads .pointer {
  cursor: pointer;
}

