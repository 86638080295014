.ReactTable {
  border: none;
  background: rgb(255, 251, 251);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 white;
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 white;
}

.ReactTableDefault .rt-th {
  padding: 16px !important;
  font-size: 14px;
  cursor: pointer;
}

.rt-tr:hover {
  background: rgb(228, 228, 228);
}

.rt-th {
  background-color: #076f9f;
  color: white;
  font-weight: 300;
  text-align: left;
}

.ReactTable .pagination-bottom {
  margin-top: 20px;
}
.ReactTable .-pagination {
  box-shadow: none;
  border: none;
}

.ReactTable .-pagination .-btn {
  text-transform: uppercase;
  font-size: 13px !important;
  letter-spacing: 1px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  line-height: 40px;
  border-radius: 2px;
  padding: 0px;
  max-width: 180px;
  display: inline-block;
  opacity: 1;
}

.ReactTable .-pagination .-previous button {
  float: right;
}
.ReactTable .-pagination .-next {
  text-align: left;
}
.ReactTable .-pagination button[disabled] {
  opacity: 0.1;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: none;
  background: none;
  width: 100%;
}
