.react-table-leads .rt-tr-group {
  background-color: #fff;
}

.react-table-leads .rt-tr-group:nth-child(2n) {
  background-color: #f2f2f2;
}

.react-table-leads .rt-th {
  cursor: pointer;
  padding: 16px 8px !important;
  font-size: 14px;
}

.react-table-leads .rt-td {
  padding: 0px !important;
  font-size: 14px;
  cursor: pointer;
}

.react-table-leads .rt-td {
  cursor: default;
  display: flex;
  align-items: center;
}

.react-table-leads .align-right {
  justify-content: flex-end;
}

.react-table-leads .align-center {
  justify-content: center;
}

.react-table-leads .rt-th {
  text-align: center;
}

.react-table-leads .pointer {
  cursor: pointer;
}
